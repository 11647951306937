import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/authentication/LoginView.vue'

const routes = [

  //All authentication route
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/factor2auth',
    name: 'Factor2Auth',
    component: () => import('../views/authentication/authenticationFactor.vue')
  },
  {
    path: '/recovery-email',
    name: 'Recovery Email',
    component: () => import('../views/authentication/RecoveryEmailView.vue')
  },
  {
    path: '/recovery-code',
    name: 'Recovery Code',
    component: () => import('../views/authentication/RecoveryCodeView.vue')
  },
  {
    path: '/update-password',
    name: 'Password Update',
    component: () => import('../views/authentication/UpdatePasswordView.vue')
  },

  //Dashbord route
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../views/DashboardView.vue')
  },

  //Companies managment route
  {
    path: '/companies',
    name: 'Companies',
    component: () => import('../views/company/CompaniesView.vue')
  },

  //company detail route
  {
    path: '/company-detail',
    name: 'Companies Detail',
    component: () => import('../views/widgets/CompanyDetails.vue')
  },

  //admins managment route
  {
    path: '/admins',
    name: 'Admins',
    component: () => import('../views/admin/AdminsView.vue')
  },

  //PGS plan suscribers route
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: () => import('../views/abonnement/AbonnementsView.vue')
  },

  //Comment route
  {
    path: '/comments',
    name: 'Comments',
    component: () => import('../views/comment/CommentsView.vue')
  },

  //newsletter suscribers route
  {
    path: '/news-subscriber',
    name: 'News Suscribers',
    component: () => import('../views/newsletter/NewsletterSubscriberView.vue')
  },
  {
    path: '/send-newsletter',
    name: 'Send Newsletter',
    component: () => import('../views/newsletter/SendNewsletterView.vue')
  },

  //blog route
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/blog/BlogView.vue')
  },

  {
    path: '/update-blog',
    name: 'Update Blog',
    component: () => import('../views/blog/BlogUpdate.vue')
  },

   //testimony route
   {
    path: '/add-testimony',
    name: 'Testimony',
    component: () => import('../views/widgets/AddTestimony.vue')
  },

  {
    path: '/update-testimony',
    name: 'Update Testimony',
    component: () => import('../views/widgets/UpdateTestimony.vue')
  },

  //FAQ routes
  {
    path: '/faq',
    name: 'FAQs',
    component: () => import('../views/faq/FaqView.vue')
  },
  {
    path: '/add-faq',
    name: 'Add FAQ',
    component: () => import('../views/widgets/AddFAQ.vue')
  },
  {
    path: '/update-topic',
    name: 'Update Topic',
    component: () => import('../views/widgets/UpdateTopic.vue')
  },
  {
    path: '/update-faq',
    name: 'Update FAQ',
    component: () => import('../views/widgets/UpdateFAQ.vue')
  },
  {
    path: '/add-topic',
    name: 'Add Topic',
    component: () => import('../views/widgets/AddTopic.vue')
  },

  //Assistance center routes
  {
    path: '/help-center',
    name: 'Help Center',
    component: () => import('../views/help-center/HelpCenterView.vue')
  },

  {
    path: '/help-responses',
    name: 'Help Details',
    component: () => import('../views/help-center/QuestionDetails.vue')
  },

  //Partners routes
  {
    path: '/partners',
    name: 'Partners',
    component: () => import('../views/partners/PartnersView.vue')
  },
  {
    path: '/partner-detail',
    name: 'View Partners',
    component: () => import('../views/widgets/PartnerDetails.vue')
  },
  {
    path: '/partners-form',
    name: 'Add Partners',
    component: () => import('../views/widgets/AddPartner.vue')
  },
  {
    path: '/pricing',
    name: 'Pricing space',
    component: () => import('../views/pricing/PricingView.vue')
  },

  // for add prices, features and plan in pricing
  {
    path: '/add-pricing',
    name: 'Add Pricing',
    component: () => import('../views/widgets/AddPricing.vue')
  },

  {
    path: '/add-feature',
    name: 'Add Feature',
    component: () => import('../views/widgets/AddPricingFeature.vue')
  },

  {
    path: '/add-plan',
    name: 'Add Plan',
    component: () => import('../views/widgets/AddPricingPlan.vue')
  },

  // Update prices, features and plan routes
  {
    path: '/update-pricing',
    name: 'Update Pricing',
    component: () => import('../views/widgets/UpdatePricing.vue')
  },

  {
    path: '/update-feature',
    name: 'Update Feature',
    component: () => import('../views/widgets/UpdatePricingFeature.vue')
  },

  {
    path: '/update-plan',
    name: 'Update Plan',
    component: () => import('../views/widgets/UpdatePricingPlan.vue')
  },

  //for partners
  {
    path: '/partners-update-form',
    name: 'Update Partners',
    component: () => import('../views/widgets/UpdatePartner.vue')
  },

  //Setting route
  {
    path: '/setting',
    name: 'Settings',
    component: () => import('../views/setting/SettingView.vue')
  },

  //Website informations route
  {
    path: '/infos-site',
    name: 'Site Informations',
    component: () => import('../views/infos-site/InfosSiteView.vue')
  },

  //Co-SuperAdmin routes
  {
    path: '/add-cosuperadmin',
    name: 'Co-Superadmin',
    component: () => import('../views/widgets/AddCoSuperAdmin.vue')
  },

  {
    path: '/update-cosuperadmin',
    name: 'Update Co-Superadmin',
    component: () => import('../views/widgets/UpdateCoSuperAdmin.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router