import 'notyf/notyf.min.css';

import { Notyf } from 'notyf';

export const API_URL = 'https://api.pgs.ctrlengine.com/api/v1'; 

const NOTYF = new Notyf({
	duration: 6000,
	position: {
		x: 'center',
		y: 'bottom',
	},
	types: [
		{
			type: 'warning',
			background: 'orange',
			icon: {
				className: 'material-icons',
				tagName: 'i',
				text: 'warning',
			},
		},
		{
			type: 'error',
			background: 'indianred',
			duration: 5000,
			dismissible: true,
		},
		{
			type: 'success',
			background: 'green',
			duration: 5000,
			dismissible: true,
		},
	],
});

export const notification = (type, message) => {
	const notyf = NOTYF;
	if (type == 'success') {
		notyf.success(message);
	} else if (type == 'error') {
		notyf.error(message);
	} else {
		notyf.warning(message);
	}
};

//dataTables 
export const DOM = "<'row'<'col-sm-12 col-md-5 pb-1'B><'col-sm-12 col-md-3 pt-1'l><'col-sm-12 col-md-4 pt-1'f>>" +
"<'row'<'col-sm-12 pt-1'tr>>" +
"<'row'<'col-sm-12 col-md-5 pt-1'i>"+
"<'col-sm-12 col-md-7 pt-1'p>>";

export const getLanguageObject = () => {
    return {
        "emptyTable": "Aucune entrée disponible pour le moment",
        "loadingRecords": "Chargement...",
        "processing": "Traitement...",
        "aria": {
            "sortAscending": ": activer pour trier la colonne par ordre croissant",
            "sortDescending": ": activer pour trier la colonne par ordre décroissant"
        },
        "select": {
            "rows": {
                "_": "%d lignes sélectionnées",
                "1": "1 ligne sélectionnée"
            },
            "cells": {
                "1": "1 cellule sélectionnée",
                "_": "%d cellules sélectionnées"
            },
            "columns": {
                "1": "1 colonne sélectionnée",
                "_": "%d colonnes sélectionnées"
            }
        },
        "autoFill": {
            "cancel": "Annuler",
            "fill": "Remplir toutes les cellules avec <i>%d</i>",
            "fillHorizontal": "Remplir les cellules horizontalement",
            "fillVertical": "Remplir les cellules verticalement"
        },
        "searchBuilder": {
            "conditions": {
                "date": {
                    "after": "Après le",
                    "before": "Avant le",
                    "between": "Entre",
                    "empty": "Vide",
                    "not": "Différent de",
                    "notBetween": "Pas entre",
                    "notEmpty": "Non vide",
                    "equals": "Égal à"
                },
                "number": {
                    "between": "Entre",
                    "empty": "Vide",
                    "gt": "Supérieur à",
                    "gte": "Supérieur ou égal à",
                    "lt": "Inférieur à",
                    "lte": "Inférieur ou égal à",
                    "not": "Différent de",
                    "notBetween": "Pas entre",
                    "notEmpty": "Non vide",
                    "equals": "Égal à"
                },
                "string": {
                    "contains": "Contient",
                    "empty": "Vide",
                    "endsWith": "Se termine par",
                    "not": "Différent de",
                    "notEmpty": "Non vide",
                    "startsWith": "Commence par",
                    "equals": "Égal à",
                    "notContains": "Ne contient pas",
                    "notEnds": "Ne termine pas par",
                    "notStarts": "Ne commence pas par"
                },
                "array": {
                    "empty": "Vide",
                    "contains": "Contient",
                    "not": "Différent de",
                    "notEmpty": "Non vide",
                    "without": "Sans",
                    "equals": "Égal à"
                }
            },
            "add": "Ajouter une condition",
            "button": {
                "0": "Recherche avancée",
                "_": "Recherche avancée (%d)"
            },
            "clearAll": "Effacer tout",
            "condition": "Condition",
            "data": "Donnée",
            "deleteTitle": "Supprimer la règle de filtrage",
            "logicAnd": "Et",
            "logicOr": "Ou",
            "title": {
                "0": "Recherche avancée",
                "_": "Recherche avancée (%d)"
            },
            "value": "Valeur"
        },
        "searchPanes": {
            "clearMessage": "Effacer tout",
            "count": "{total}",
            "title": "Filtres actifs - %d",
            "collapse": {
                "0": "Volet de recherche",
                "_": "Volet de recherche (%d)"
            },
            "countFiltered": "{shown} ({total})",
            "emptyPanes": "Pas de volet de recherche",
            "loadMessage": "Chargement du volet de recherche...",
            "collapseMessage": "Réduire tout",
            "showMessage": "Montrer tout"
        },
        "buttons": {
            "collection": "Collection",
            "colvis": "Visibilité colonnes",
            "colvisRestore": "Rétablir visibilité",
            "copy": "<i class='fa-solid fa-copy'></i>",
            "copySuccess": {
                "1": "1 ligne copiée dans le presse-papier",
                "_": "%ds lignes copiées dans le presse-papier"
            },
            "copyTitle": "Copier dans le presse-papier",
            "csv": "<i class='fa-solid fa-file-csv'></i>",
            "excel": "<i class='fa-solid fa-file-excel'></i>",
            "pageLength": {
                "-1": "Afficher toutes les lignes",
                "_": "Afficher %d lignes"
            },
            "pdf": "<i class='fa-solid fa-file-pdf'></i>",
            "print": "<i class='fa-solid fa-print'></i>",
            "copyKeys": "Appuyez sur ctrl ou u2318 + C pour copier les données du tableau dans votre presse-papier.",
            "createState": "Créer un état",
            "removeAllStates": "Supprimer tous les états",
            "removeState": "Supprimer",
            "renameState": "Renommer",
            "savedStates": "États sauvegardés",
            "stateRestore": "État %d",
            "updateState": "Mettre à jour"
        },
        "decimal": ",",
        "search": "Rechercher:",
        "datetime": {
            "previous": "Précédent",
            "next": "Suivant",
            "hours": "Heures",
            "minutes": "Minutes",
            "seconds": "Secondes",
            "unknown": "-",
            "amPm": [
                "am",
                "pm"
            ],
            "months": {
                "0": "Janvier",
                "2": "Mars",
                "3": "Avril",
                "4": "Mai",
                "5": "Juin",
                "6": "Juillet",
                "8": "Septembre",
                "9": "Octobre",
                "10": "Novembre",
                "1": "Février",
                "11": "Décembre",
                "7": "Août"
            },
            "weekdays": [
                "Dim",
                "Lun",
                "Mar",
                "Mer",
                "Jeu",
                "Ven",
                "Sam"
            ]
        },
        "editor": {
            "close": "Fermer",
            "create": {
                "title": "Créer une nouvelle entrée",
                "button": "Nouveau",
                "submit": "Créer"
            },
            "edit": {
                "button": "Editer",
                "title": "Editer Entrée",
                "submit": "Mettre à jour"
            },
            "remove": {
                "button": "Supprimer",
                "title": "Supprimer",
                "submit": "Supprimer",
                "confirm": {
                    "_": "Êtes-vous sûr de vouloir supprimer %d lignes ?",
                    "1": "Êtes-vous sûr de vouloir supprimer 1 ligne ?"
                }
            },
            "multi": {
                "title": "Valeurs multiples",
                "info": "Les éléments sélectionnés contiennent différentes valeurs pour cette entrée. Pour modifier et définir tous les éléments de cette entrée à la même valeur, cliquez ou tapez ici, sinon ils conserveront leurs valeurs individuelles.",
                "restore": "Annuler les modifications",
                "noMulti": "Ce champ peut être modifié individuellement, mais ne fait pas partie d'un groupe. "
            },
            "error": {
                "system": "Une erreur système s'est produite (<a target=\"\\\" rel=\"nofollow\" href=\"\\\">Plus d'information</a>)."
            }
        },
        "stateRestore": {
            "removeSubmit": "Supprimer",
            "creationModal": {
                "button": "Créer",
                "order": "Tri",
                "paging": "Pagination",
                "scroller": "Position du défilement",
                "search": "Recherche",
                "select": "Sélection",
                "columns": {
                    "search": "Recherche par colonne",
                    "visible": "Visibilité des colonnes"
                },
                "name": "Nom :",
                "searchBuilder": "Recherche avancée",
                "title": "Créer un nouvel état",
                "toggleLabel": "Inclus :"
            },
            "renameButton": "Renommer",
            "duplicateError": "Il existe déjà un état avec ce nom.",
            "emptyError": "Le nom ne peut pas être vide.",
            "emptyStates": "Aucun état sauvegardé",
            "removeConfirm": "Voulez vous vraiment supprimer %s ?",
            "removeError": "Échec de la suppression de l'état.",
            "removeJoiner": "et",
            "removeTitle": "Supprimer l'état",
            "renameLabel": "Nouveau nom pour %s :",
            "renameTitle": "Renommer l'état"
        },
        "info": "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
        "infoEmpty": "Affichage de 0 à 0 sur 0 entrée",
        "infoFiltered": "(filtrées depuis un total de _MAX_ entrées)",
        "lengthMenu": "Afficher _MENU_ entrées",
        "paginate": {
            "first": "Première",
            "last": "Dernière",
            "next": "<i class='fa-solid fa-angles-right'></i>",
            "previous": "<i class='fa-solid fa-angles-left'></i>"
        },
        "zeroRecords": "Aucune entrée correspondante trouvée",
        "thousands": " "
    };
  };

