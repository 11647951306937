import axiosInstance from '../axiosConfig.js';

export default class AuthService{
  //*************************************************************************************//
  //SuperAdmin login function
  //*************************************************************************************//
  adminLogin(adminInfos) {

      var data = JSON.stringify(adminInfos);

      var config = {
        method: 'put',
        url: '/superadmin/auth/login',
        data : data
      };
      return axiosInstance(config)
      .then(response=>response)
      .catch(error=>error);
  }

  //*************************************************************************************//
  //function for check if 2 authentication is activate
  //*************************************************************************************//
  f2AuthStatus(token) {
    var config = {
      method: 'PUT',
      url: '/superadmin/profile/toggle-2fauth',
      headers: { 
        'Authorization': 'Bearer '+token, 
      },
    };
    return axiosInstance(config)
    .then(response=>response)
    .catch(error=>error);
  }

  //*************************************************************************************//
  //function for get SuperAdmin after login
  //*************************************************************************************//
  getSuperAminInfo(token) {
    var config = {
      method: 'GET',
      url: '/superadmin/profile/get-data',
      headers: { 
        'Authorization': 'Bearer '+token, 
      },
    };
    return axiosInstance(config)
    .then(response=>response)
    .catch(error=>error);
  }
  
  //*************************************************************************************//
  //function for submit email if want recovery password
  //*************************************************************************************//
  recoveryEmail(recovEmail) {
    var data = JSON.stringify(recovEmail);
      var config = {
        method: 'post',
        url: '/superadmin/auth/send-password-recovery-email',
        data : data
      };
    return axiosInstance(config)
    .then(response=>response)
    .catch(error=>error);
  }

  //*************************************************************************************//
  //function for submit code if is 2 authentication
  //*************************************************************************************//
  login2Fauth(loginCode) {
        var config = {
          method: 'PUT',
          url: '/superadmin/auth/login-2fauth?code='+loginCode
        };
      return axiosInstance(config)
      .then(response=>response)
      .catch(error=>error);
  }

  //*************************************************************************************//
  //function for submit code if want recovery password
  //*************************************************************************************//
  Code(recovCode) {
  var data = JSON.stringify(recovCode);
    var config = {
      method: 'post',
      url: '/superadmin/auth/verify-recovery-code',
      data : data
    };
    return axiosInstance(config)
    .then(response=>response)
    .catch(error=>error);
  }

  //*************************************************************************************//
  //function for update password
  //*************************************************************************************//
  updatePassword(passwordInfos) {
    var data = JSON.stringify(passwordInfos);
      var config = {
        method: 'post',
        url: '/superadmin/auth/update-password-and-login',
        data : data
      };
    return axiosInstance(config)
    .then(response=>response)
    .catch(error=>error);
  }
}