<template>
    <div v-if="!isMobile">
        <router-view ref="myPage" />
    </div>
    
    <div v-if="isMobile" class="modal-overlay">
        <div class="m-modal" >
            <div class="m-check mx-auto">
                <i class="fa-solid fa-triangle-exclamation check"></i>
            </div>
            <h6>Page non disponible</h6>
            <p>Désolé! Cette page n'est pas disponible sur mobile</p>
            <button class="mx-auto valide">D'accord, Merci</button>
        </div>
    </div>
    
</template>

<script>
export default {
  data() {
    return {
      isMobile: window.innerWidth < 768,
    };
  },
  mounted() {
    if (this.isMobile) {
      this.$refs.myPage.hidden = true;
    }
  },
};
</script>

<style>
  body{
     background-color: #EAF3FF;
  }

  .alert-error {
        padding: 20px;
        position: fixed;
        top: 10px;
        z-index: 999;
        border-radius: 5px;
        right:10px;
        background-color: #f44336;
        border-left: 10px solid #8f241d;
        color: white;
    }

    .alert-success {
        padding: 20px;
        position: fixed;
        top: 10px;
        z-index: 999;
        border-radius: 5px;
        right:10px;
        border-left: 10px solid #13d13c;
        background-color: #0004d3;
        color: white;
    }

    .closebtn {
        margin-left: 15px;
        color: white;
        font-weight: bold;
        float: right;
        font-size: 22px;
        line-height: 20px;
        cursor: pointer;
        transition: 0.3s;
    }

    .closebtn:hover {
        color: rgb(114, 105, 105);
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        display: flex;
        justify-content: center;
        background-color: #000000be;
    }
    
    .m-modal {
        text-align: center;
        background-color: white;
        height: 450px;
        width: 500px;
        margin-top: 8%;
        padding: 45px 0;
        border-radius: 15px;
    }

    .m-check{
        width: 150px;
        height: 150px;
        background-color: #0000000e;
        border-radius: 100%;
    }
    
    .check {
        margin-top: 21%;
        font-size: 80px;
        color: #941717;
    }
    
    h6 {
        font-weight: 500;
        font-size: 28px;
        margin: 20px 0;
        color: #2b2a2a;
    }
    
    p {
        font-size: 16px;
        margin: 20px 10%;
        color: #4d4b4bcc;
    }
    
    .valide {
        background-color: #941717;
        width: 150px;
        height: 40px;
        color: white;
        font-size: 14px;
        border-radius: 6px;
        margin-top: 20px;
        outline: none;
        border: none;
    }

    .close {
        background-color: #ccc8c86b;
        width: 150px;
        height: 40px;
        color: gray;
        font-size: 14px;
        border-radius: 6px;
        margin-top: 50px;
        outline: none;
        border: none;
    }

    .valide:hover, .close:hover {
        transform: scale(0.95);
    }

    @media screen and (max-width: 414px) {
        .m-modal {
            width: 85%;
        }
    }


    @media screen and (max-width: 375px) {
        .m-modal {
            width: 85%;
        }
    }


    @media screen and (max-width: 280px) {
        .m-modal {
            width: 85%;
        }
    }

</style>
