import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      token: localStorage.getItem('token') || '',
      adminInfo: {
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        photo: null, 
        id: ''
      },
    };
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },

    SET_ADMIN_INFO(state, adminInfo) {
        state.adminInfo = adminInfo;
    },

    RESET_AUTH_STATE(state) {
        state.token = '';
    },
  },
  actions: {
    setToken({ commit }, token) {
      localStorage.setItem('token', token);
      commit('SET_TOKEN', token);
    },

    setAdminInfo({ commit }, adminInfo) {
        commit('SET_ADMIN_INFO', adminInfo);
    },

    logout({ commit }) {
        localStorage.removeItem('token');
        
        localStorage.removeItem('sa_fname');
        localStorage.removeItem('sa_lname');
        localStorage.removeItem('sa_mail');
        localStorage.removeItem('sa_phone');
        localStorage.removeItem('sa_img');
        localStorage.removeItem('sa_id');
    
        commit('RESET_AUTH_STATE');
      },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.token;
    },
  }
});

export default store;
