<template>
    <div class="body">
        <div class="banner">
            <img class="banner-img" src="../../assets/left-to-right.jpg" alt="" >
        </div>
        <div class="card">
            <div class="card__logo">
                <img class="card_img" src="../../assets/images/logo-avec-fond-noir.png" alt="">
            </div>
            <h2 class="card__title">Bienvenue sur Pro Gestion Soft</h2>
            <h1 class="card__subtitle">Connectez-vous pour continuer</h1>
            <div class="form-row">
                <input v-model="email" class="form-row__input" type="email" placeholder="Adresse mail"/>
            </div>
            <div class="form-row">
                <input v-model="password" class="form-row__input" :type="showPassword ? 'text' : 'password'" id="id_password" placeholder="Mot de passe"/>
            </div>
            <div class="form-row">
                <input type="checkbox" v-model="showPassword" @click="toggleShow"/> Afficher le mot de passe
            </div>
            <span>
                <router-link to="/recovery-email" class="float-end pass-forgot">
                    Mot de passe oublié?
                </router-link>
            </span>
            <div class="form-row">
                <button @click="login()" class="button" :class="{'button--disabled' : !validatedFields}" >
                    <span v-if="status == 'loading'">Connexion en cours...</span>
                    <span v-else>Connexion</span>
                </button>
            </div>
        </div>
    </div>

     <!-- Loading -->
     <LoadingModal v-show="loading" @close-modal="loading = false"/>
     <AccessModal v-show="accessError" @close-modal="accessError = false"/>

     <div v-if="!isError" class="alert-error">
         <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span> 
         <strong><i class="fa-solid fa-circle-info"></i></strong>   {{errors}}
     </div>
     <div v-if="!isSuccess" class="alert-success">
         <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span> 
         <strong><i class="fa-solid fa-octagon-check"></i></strong>   {{success}}
     </div>
</template>

<script>
import AuthService from '../../services/AuthServices.js'
  import AccessModal from '@/components/AutaurizationModal'

import LoadingModal from '@/components/LoadingModal'
export default {
    name: 'LoginView',

    components: {LoadingModal,AccessModal},

    data: function () {
        return {
            ready: false,
            isMobile: window.innerWidth < 768,
            status:'',
            email: '',
            password: '',
            rememberMe: false,
            authService: null,
            errors: '',
            showPassword: false,
            success: '',
            loading: false,
            accessError: false,
        }
    },

    created: function() {
        this.authService = new AuthService();
    },
    
    mounted: function () {
        document.title = "Login.::.Pro Gestion Soft";
        this.tokenIsValid();
        if (this.isMobile) {
            this.$refs.myPage.hidden = true;
        }
        
    },

    watch: {
        '$store.getters.isAuthenticated'(newValue) {
            if (newValue) {
                this.$router.push('/');
            }
        }
    }, 

    computed: {
        validatedFields: function () {
            if (this.email != "" && this.password != "") {
                return true;
            } else {
                return false;
            }
        },

        isError: function () {
            if (this.errors == "") {
                return true;
            } else {
                return false;
            }
        },

        isSuccess: function () {
            if (this.success == "") {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        toggleShow: function() {
            this.showPassword = !this.showPassword;
        },

        login: function () {
            this.loading = true;
            const self = this;

            self.errors = "";
            self.success = "";
            
            if(this.email !="" && this.password !=""){
                self.status = "loading";
                self.authService.adminLogin({
                    "login": self.email,
                    "password": self.password,
                    "rememberMe": self.rememberMe
                }).then(function (responses) {
                    self.loading = false;
                    self.status="";
                    if(responses.status!=200) {
                        if(responses.response.status===401){
                            self.errors = responses.response.data.message;
                        }else{
                            self.errors = responses.response.data.message;
                        }
                    }else{
                        if(responses.data.need2FAuth == true){
                            self.$router.push('/factor2auth');
                        }else{
                            self.getSuperAminInfos(responses.data.token);
                            self.$store.dispatch('setToken', responses.data.token);
                        }
                    }
                    
                })
                .catch(function (error) {
                    self.loading = false;
                    self.status="";
                    self.errors = error;
                    //console.log(error);
                });
            }else{
                self.loading = false;
                self.errors = "Veuillez renseigner les champs svp"
            }
        },

        getSuperAminInfos: function(token){
            const self = this;
            self.errors = "";
            self.authService.getSuperAminInfo(token).then(function (responses) {
                self.status="";
                if(responses.status!=200) {
                    self.errors = "Une erreur s'est  produite";
                }else{
                   self.$store.dispatch('setAdminInfo', {
                        firstname: responses.data.data.firstname,
                        lastname: responses.data.data.lastname,
                        email: responses.data.data.emailAddress,
                        phoneNumber: responses.data.data.phoneNumber,
                        photo: responses.data.data.photo!=null?responses.data.data.photo.url:null,
                        id: responses.data.data.id
                    });
                }
            })
            .catch(function (error) {
                self.status="";
                console.log(error);
            });
        },

        tokenIsValid: function() {
            if (this.$store.getters.isAuthenticated) {
                this.$router.push('/');
            }
        }
    }

    
}
</script>

<style scoped>
     .body {
        background-image: linear-gradient(62deg, #162031 0%, #EAF3FF 100%);
        display: flex;
        position: absolute;
        top: 0 ;
        bottom: 0;
        left: 0;
        right: 0;
        align-items: center;
        justify-content: center;
        min-height: 88vh;
        padding:32px;
    }

    .banner {
        position: absolute;
        widows: 100%;
        top: 0 ;
        left: 0;
        right: 0;
    }

    .banner-img{
        width:100%;
        height: 100vh;
    }

    .card {
        max-width: 100%;
        z-index: 999;
        width: 560px;
        background:white;
        border-radius: 13px;
        padding:32px;
    }

    .card__logo {
        margin: 20px auto;
        width: 150px;
        height: 150px;
        /*background: #EAF3FF;*/
    }

    .card__title {
        text-align:center;
        font-weight: 700;
        font-size: 25px;
    }
    .card__subtitle {
        text-align: center;
        color:#666;
        font-size: 20px;
        font-weight: 300;
    }

    .form-row {
        display: flex;
        width: 100%;
        margin: 12px 3px;
        gap:16px;
        flex-wrap: wrap;
    }

    .form-row__input {
        padding:8px;
        border: none;
        border-radius: 8px;
        background:#f2f2f2;
        font-weight: 500;
        font-size: 16px;
        flex:1;
        height: 50px;
        outline: none;
        min-width: 100px;
        color: black;
    }

    .form-row__input::placeholder {
        color:#aaaaaa;
    }

    .card_img {
    max-width: 100%;
    border-radius: 8px;
    }
    
    .button {
        background: #0072FF;
        color:white;
        border-radius: 8px;
        font-weight: 800;
        font-size: 15px;
        border: none;
        width: 100%;
        padding: 16px;
        transition: .4s background-color;
    }
    .card__action {
        color:#0072FF;
        text-decoration: underline;
    }
    .card__action:hover {
        cursor:pointer;
    }
    .button:hover {
        cursor:pointer;
        background: #1976D2;
    }
    .button--disabled {
        background:#1976D2;
        color:#ececec
    }
    .button--disabled:hover {
        cursor:not-allowed;
        background:#155aa0;
    }

    .pass-forgot{
        color: #1976D2;
        margin-bottom: 1.2em;
        text-decoration: none;
        cursor: pointer;
    }

    .alert-error {
        padding: 20px;
        position: fixed;
        top: 10px;
        z-index: 999;
        border-radius: 5px;
        right:10px;
        background-color: #f44336;
        border-left: 10px solid #8f241d;
        color: white;
    }

    .alert-success {
        padding: 20px;
        position: fixed;
        top: 10px;
        z-index: 999;
        border-radius: 5px;
        right:10px;
        border-left: 10px solid #13d13c;
        background-color: #4aad57;
        color: white;
    }

    .closebtn {
        margin-left: 15px;
        color: white;
        font-weight: bold;
        float: right;
        font-size: 22px;
        line-height: 20px;
        cursor: pointer;
        transition: 0.3s;
    }

    .closebtn:hover {
        color: rgb(114, 105, 105);
    }
</style>>